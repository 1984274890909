import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com'; // updated import for emailjs

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    number: Yup.string().required('Contact Number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    isChecked: Yup.boolean().oneOf([true], 'Please accept terms and conditions').required('You must accept terms and conditions'),
});

const ContactUs = () => {
    const initialValues = {
        name: '',
        email: '',
        number: '',
        isChecked: false,
    };

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        emailjs.sendForm('service_v73c39b', 'template_8o5w5jn', '#yourcontactformid', 'Q_tnUooVuTOT2eItg')
            .then(
                (result) => {
                    resetForm();
                    window.location.href = 'https://thankyou.knsbillore.com/'; 
                },
                (error) => {
                    Swal.fire('Oops!', 'Something went wrong. Please try again', 'error');
                }
            )
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className='container'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched }) => (
                    <Form id="yourcontactformid">
                        <div className="row g-3 my-5 background-colorized rounded p-2 m-2">
                            <div className="col-lg-4 col-md-12">
                                <Field type="text"
                                    className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                    placeholder="Name"
                                    aria-label="Name"
                                    name='name'
                                />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <Field
                                    type="text"
                                    className={`form-control ${errors.number && touched.number ? 'is-invalid' : ''}`}
                                    placeholder="Contact Number"
                                    aria-label="Contact Number"
                                    name='number'
                                />
                                <ErrorMessage name="number" component="div" className="invalid-feedback" />
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <Field
                                    type="email"
                                    className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                    placeholder="Email"
                                    aria-label="Email"
                                    name='email'
                                />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-check d-flex justify-content-start">
                                <Field
                                    className={`form-check-input me-2 ${errors.isChecked && touched.isChecked ? 'is-invalid' : ''}`}
                                    type="checkbox"
                                    id="isChecked"
                                    name="isChecked"
                                />
                                <label className="form-check-label text-dark" htmlFor="isChecked">
                                    Accept all{' '}
                                    <a className="textdecor" href="https://www.knsgroup.in/privacy" target="_blank" rel="noopener noreferrer">
                                        Terms
                                    </a>{' '}
                                    &{' '}
                                    <a className="textdecor" href="https://www.knsgroup.in/privacy" target="_blank" rel="noopener noreferrer">
                                        Conditions
                                    </a>
                                </label>
                                <ErrorMessage name="isChecked" component="div" className="invalid-feedback" />
                            </div>
                            <div className="col-ms-auto">
                                <button
                                    type="submit"
                                    className="btn-setup"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactUs;

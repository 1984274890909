import React from 'react';
import ameone from '../../Assets/eco.webp';
import ametwo from '../../Assets/sportspage.webp';


const Amenities = () => {
    return (
        <div className='row bg-light'>
            <h2 className='text-dark my-5'>Amenities</h2> 

             <div className='col-lg-6 col-md-12' >
            <img src={ameone} className='w-100' alt=''/>            
            </div>

            <div className='col-lg-6 col-md-12' >
            <img src={ametwo} className='w-100' alt=''/>            
            </div>
           
           
        </div>
    );
};

export default Amenities;
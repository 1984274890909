import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logoss from '../../Assets/logo kns and billore.png';
import'./footerSetup.css';
import PhoneNumberLink from '../PhoneNumberLink/PhoneNumberLink';

const Footersetup = () => {
    return (
        <div className='background-colouring'>
            <div className='row'>
                <div className='col-lg-4 col-md-12 p-4'>
                    <img src={logoss} className='w-50' alt=''/>   
                </div>
                <div className='col-lg-8 col-md-12 p-4'>
              
                <h6 className='text-light'> PRM/KA/RERA/1251/472/PR/020724/006937</h6>
                <div>
                <p className='text-light'>Call Us Today : </p>
                <PhoneNumberLink phoneNumber="8880 66 33 44" />
                <PhoneNumberLink phoneNumber="8880 66 22 33" />
                </div>
               
                

                <div>
                    <p className='text-light'>copyright @ reserved by KNS Infrastructure Private Limited</p>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Footersetup;
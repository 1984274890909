import React from 'react';

const Location = () => {
    return (
        <div className='container my-5'>
            <div className='row'>
                <div className='col-lg-12'>
                    <h2 className='text-dark'>Location</h2>
        
                    <iframe
                        title='KNS Billore Premium plots on Doddaballapura Main Road'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62142.20431254225!2d77.4685807486328!3d13.232359200000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae217f691c07c5%3A0x9590e490fc5bbd95!2sKNS%20Billore!5e0!3m2!1sen!2sin!4v1721199299837!5m2!1sen!2sin" 
                        width="100%" 
                        height="600"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Location;



// direct Image 


// import React from 'react';
// import location from '../../Assets/BilloreMap.png';


// const Location = () => {
//     return (
//         <div className='container my-5 '>
//             <div className='row'>
//             <div className='col-lg-12'>
//                  <h2 className='text-dark'>Location</h2>
//                 <a href='https://maps.app.goo.gl/XnwFBFV7JGTgPSQZ7' target='_blank'><img src={location} className='w-100' alt=''/> </a>
//             </div>
            
//             </div>
//         </div>
//     );
// };

// export default Location;

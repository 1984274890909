import React from 'react';
import detailsweb from '../../Assets/approvalweb.webp';
import detailsmob from '../../Assets/approvalmob.webp';

const ProjectDetails = () => {
    return (
        <div className='mt-5'>
            <div > 
            
            <img src={detailsweb} className='w-100 changerweb' alt='background-photo'/>
            <img src={detailsmob}  className='w-100 changermob' alt='background-photo'/>

        </div>
        </div>
    );
};

export default ProjectDetails;
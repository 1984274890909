import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  number: Yup.string("Enter the Owner's Mobile Number").required("Contact Number is required"),
  email: Yup.string().email('Invalid email').required('Email is required'),
  isChecked: Yup.boolean().oneOf([true], 'Please accept terms and conditions').required('You must accept terms and conditions'),
});

const Modaler = () => {
  const initialValues = {
    name: '',
    email: '',
    number: '',
    isChecked: false,
  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowModal(true);
    }, 4000);
    return () => clearTimeout(timeout);
  }, []);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    emailjs
        .sendForm('service_v73c39b', 'template_8o5w5jn', '#yourmodelformid', 'Q_tnUooVuTOT2eItg')
        .then(
            (result) => {
                resetForm(); 
                window.location.href = 'https://thankyou.knsbillore.com/'; 
            },
            (error) => {
                Swal.fire('Oops!', 'Something went wrong. Please try again', 'error');
            }
        )
        .finally(() => {
            setSubmitting(false); 
        });
};
  return (
    <div>
      {/* Modal is here */}
      <div className="modal mt-5" tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog " role="document">
          <div className="modal-content ">
            <button type="button" className="btn-close position-absolute top-0 end-0 mt-2 me-2 " onClick={() => setShowModal(false)}></button>
            <h4 className='text-dark mt-5'>Get your best offer. Today!</h4>
            <div className="modal-body d-flex justify-content-center ">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form id="yourmodelformid">
                    <div class="row g-3 my-5 background-colorized rounded p-2 m-2">
                      <div class="col-lg-12 col-md-12">
                        <Field
                          type="text"
                          className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                          placeholder="Name"
                          name="name"
                        />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                      </div>
                      <div class="col-lg-12 col-md-12">
                        <Field
                          type="tel"
                          className={`form-control ${errors.number && touched.number ? 'is-invalid' : ''}`}
                          placeholder="Contact Number"
                          name="number"
                        />
                        <ErrorMessage name="number" component="div" className="invalid-feedback" />
                      </div>
                      <div class="col-lg-12 col-md-12">
                        <Field
                          type="email"
                          className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                          placeholder="Email"
                          name="email"
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </div>
                      <div class="form-check d-flex justify-content-start  ">
                        <Field
                          className={`form-check-input me-2 ${errors.isChecked && touched.isChecked ? 'is-invalid' : ''}`}
                          type="checkbox"
                          id="flexCheckDefault"
                          name="isChecked"
                        />
                        <label className="form-check-label text-dark" htmlFor="flexCheckDefault">
                          Accept all{' '}
                          <Link className="textdecor" to="https://www.knsgroup.in/privacy">
                            Terms
                          </Link>{' '}
                          &{' '}
                          <Link className="textdecor" to="https://www.knsgroup.in/privacy">
                            Conditions
                          </Link>
                        </label>
                      </div>
                      <div class="col-lg-12 col-md-12">
                        <ErrorMessage className="form-check d-flex justify-content-start invalid-feedback" name="isChecked" component="div" />
                      </div>
                      <div className="col-ms-auto">
                        <button
                          type="submit"
                          className="btn-setup"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modaler;